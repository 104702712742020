import entrypoint from "../config/params";
import { uploadDocument, uploadImage } from "../services/mediaUploader";

export async function handleResourceCreate(params) {
  const data = params.data;

  const resource_images = [];
  const resource_attachments = [];

  if (data.images && data.images.length > 0) {
    for (let i = 0; i < data.images.length; i++) {
      const image = await uploadImage(data.images[i].rawFile);
      resource_images.push(image["@id"]);
    }
  }

  if (data.attachments && data.attachments.length > 0) {
    for (let i = 0; i < data.attachments.length; i++) {
      const document = await uploadDocument(data.attachments[i].rawFile);
      resource_attachments.push(document["@id"]);
    }
  }

  const resData = {
    ...data,
  };

  if (resource_images.length > 0) {
    resData.images = resource_images;
  }

  if (resource_attachments.length > 0) {
    resData.attachments = resource_attachments;
  }

  return resData;
}

export async function handleResourceUpdate(params) {
  const data = params.data;
  const resource_images = [];
  const resource_attachments = [];

  if (data.company) {
    data.company = data.company["@id"];
  }

  if (data.site) {
    data.site = data.site["@id"];
  }

  if (data.vehicle) {
    data.vehicle = data.vehicle["@id"];
  }

  if (data.operator) {
    data.operator = data.operator["@id"];
  }

  if (data.driver) {
    data.driver = data.driver["@id"];
  }

  if (data.supplier) {
    data.supplier = data.supplier["@id"];
  }

  if (data.cycle) {
    data.cycle = data.cycle["@id"];
  }

  if (data.attachments && data.attachments.length > 0) {
    console.log("attachments", data.attachments.length);

    for (let i = 0; i < data.attachments.length; i++) {
      if (params.data.attachments[i]["@id"]) {
        resource_attachments.push(data.attachments[i]["@id"]);
      } else if (data.attachments[i].rawFile instanceof File) {
        const attachment = await uploadDocument(data.attachments[i].rawFile);
        resource_attachments.push(attachment["@id"]);
      }
    }
  }

  if (data.images && data.images.length > 0) {
    for (let i = 0; i < data.images.length; i++) {
      if (params.data.images[i]["@id"]) {
        resource_images.push(data.images[i]["@id"]);
      } else if (data.images[i].rawFile instanceof File) {
        const image = await uploadImage(data.images[i].rawFile);
        resource_images.push(image["@id"]);
      }
    }
  }

  const resData = {
    ...data,
    images: resource_images,
    attachments: resource_attachments,
  };
  return resData;
}

async function handleImageCreate(resource, image) {
  const body = { resource, image };

  return fetch(`${entrypoint}/images`, {
    body: JSON.stringify(body),
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      "Content-Type": `application/ld+json`,
    }),
    method: "POST",
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}

async function handleDocumentCreate(resource, document) {
  const body = { resource, document };

  return fetch(`${entrypoint}/attachments`, {
    body: JSON.stringify(body),
    headers: new Headers({
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
      "Content-Type": `application/ld+json`,
    }),
    method: "POST",
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (json) {
      return json;
    });
}
